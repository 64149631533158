import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './hsr-guides.scss';

const HSRRerollPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Apocalyptic Shadow</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_apocalyptic_shadow.webp"
            alt="Apocalyptic Shadow"
          />
        </div>
        <div className="page-details">
          <h1>Apocalyptic Shadow</h1>
          <h2>
            Everything you need to know about Apocalyptic Shadow mode in Honkai
            Star Rail!
          </h2>
          <p>
            Last updated: <strong>25/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Overview" />
        <p>
          Apocalyptic Shadow is a game mode in which you will assemble 2
          different teams to fight 2 different bosses. Each team will take on
          one boss and your goal is to defeat them as quickly as possible.
          Completing this game mode will give you rewards such as Stellar Jades
          and Jade Feathers.
        </p>
        <p>
          This game mode unlocks after completing the Grim Film of Finality
          Adventure Mission, after which, it can be accessed via the Interastral
          Peace Guide or by heading to the Apocalyptic Shadow marker in the
          Dreamflux Reef area located in the Penacony map.
        </p>
        <StaticImage
          src="../../../images/starrail/generic/apocalyptic_shadow_1.webp"
          alt="Apocalyptic Shadow 1"
        />
        <p>
          There are a total of 4 difficulty levels that you can challenge. Your
          2 teams will be fighting 2 separate bosses with the first team
          fighting one boss and the second team fighting the other boss. Points
          are awarded to you based on how many bosses you’ve defeated and how
          quickly you defeated them. The faster you take them down, the more
          points you are awarded. The number of points that you have at the end
          of the two battles will determine the reward that you get. The higher
          your points, the more rewards you will get.
        </p>
        <StaticImage
          src="../../../images/starrail/generic/apocalyptic_shadow_2.webp"
          alt="Apocalyptic Shadow 2"
        />
        <p>
          Unlike Memory of Chaos and Pure Fiction, there is no cycle limit for
          Apocalyptic Shadow. Instead there is an Action Value point meter that
          will tick down each time an ally or enemy takes their turn. This point
          meter determines how many points you will obtain at the end of the
          battle, so you are still incentivized to finish the battle as quickly
          as possible.
        </p>
        <p>
          In Apocalyptic Shadow, there will be a Ruinous Embers, a global
          passive effect, active. This effect will typically provide a buff to
          encourage the use of certain team archetypes, so effective use of this
          effect will provide a great power boost to your team.
        </p>
        <StaticImage
          src="../../../images/starrail/generic/apocalyptic_shadow_3.webp"
          alt="Apocalyptic Shadow 3"
        />
        <p>
          When building your teams for this game mode, you will also be able to
          select 1 of 3 passive effects, called Finality’s Axiom, that will be
          applied to each of your team. However, each effect can only be
          selected once, so you will need to select 2 different effects for your
          2 teams.
        </p>
        <StaticImage
          src="../../../images/starrail/generic/apocalyptic_shadow_4.webp"
          alt="Apocalyptic Shadow 4"
        />
        <p>
          Each boss will also have a set of traits that changes or enhances
          their characteristics and abilities, so it is important to pay
          attention to these traits when fighting each boss. Effective use of
          the Ruinous Embers and Finality’s Axiom effects, alongside keeping the
          bosses’ traits in mind during the battle, will greatly enhance your
          team’s combat effectiveness.
        </p>
        <StaticImage
          src="../../../images/starrail/generic/apocalyptic_shadow_5.webp"
          alt="Apocalyptic Shadow 5"
        />
        <p>
          Similar to the Memory of Chaos and Pure Fiction game modes,
          Apocalyptic Shadow will reset periodically. After each reset, you will
          be able to challenge the bosses to obtain the Stellar Jades and Jade
          Feather rewards again. In addition to featuring different bosses with
          their own set of traits, each reset of Apocalyptic Shadow will also
          change the Ruinous Embers and Finality’s Axiom effects for the
          duration of that cycle.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRRerollPage;

export const Head: React.FC = () => (
  <Seo
    title="Apocalyptic Shadow | Honkai: Star Rail | Prydwen Institute"
    description="Everything you need to know about Apocalyptic Shadow mode in Honkai Star Rail!"
  />
);
